<template>
    <div id="title" v-bind:style="styleObject" v-bind:class="{acceesibility: acceesibility}">
        <h1>
            {{title}}
        </h1>
    </div>
</template>

<script>
export default {
    name:"PageTitle",
    props: ['title', 'bg'],
    data(){
        return {
            styleObject: {
                background: this.bg ?  `url("${this.bg}")` : 'url("/images/maintenance.jpg")',
                backgroundSize: 'cover'
            }
        }
    },
    computed:{
      acceesibility(){
        return this.$store.getters.accessibilitySetting.showAccessibilityBar;
      }
    },
}
</script>

<style lang="scss" scoped>
div{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    max-height: 370px;
    margin-top: -75px;
    &::before{
        content: ""; // ::before and ::after both require content
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 60vh;
        max-height: 370px;
        background-image: linear-gradient(#000, #000);
        opacity: .4;
    }
    &.acceesibility{
        margin-top: -119px;
    }
}
h1{
    font-size: 3.25rem;
    text-align: center;
    line-height: 3.75rem;
    padding-top: 2rem;
    color: #fff;
    margin: 0 1rem;
    z-index: 2;
    font-weight: 200;
}
@media (max-width: $mobile) {
    div{
        background-position: center !important;
        background-attachment: scroll !important; 
    }
    h1{
        padding-top: 5rem;
    }
}
</style>
